<template>
	<v-sheet>
		<render-content v-if="loggedIn" id="clientcontactus"> </render-content>
		<render-content v-else id="contact"> </render-content>
		<contact-form> </contact-form>
	</v-sheet>
</template>
<script>
import RenderContent from "@c/ui/RenderContent.vue";
import ContactForm from "@c/ui/ContactForm.vue";
export default {
	name: "Contact",
	components: {
		RenderContent,
		ContactForm,
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.logged_in;
		},
	},
};
</script>
